* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
}

.videos {
	width: 100%;
	height: 100vh;

	.video {
		width: 100%;
		height: 100%;
		background: #000;

		video {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		&.absent {
			display: none;
		}
	}
	.me {
		position: absolute;
		top: 30px;
		left: 30px;
		z-index: 9;
		background: blue;
		width: 300px;
		height: 165px;
		border-radius: 10px;
		overflow: hidden;
		transition: 0.2s ease-in-out;

		@media (max-width: 480px) {
			width: 115px;
			height: 200px;
			left: 10px;
			top: calc(100% - 210px);
			// bottom: 15px;
		}

		video {
			object-fit: cover;
		}

		&.alone {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 0;
		}
	}
}
video {
	transform: rotateY(180deg);
}
.controls {
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 99;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 80px;
	overflow-x: hidden;

	.control {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		background-color: white;
		opacity: 1;
		padding: 0.5em;
		cursor: pointer;

		transition: 0.15s ease-in-out;
		transform: translateX(100px);

		@media (max-width: 480px) {
			transform: translateX(0px);
		}

		&.end {
			padding: 0;
		}

		img {
			width: 100%;
			height: 100%;
		}
		&.disabled {
			background-color: #999999;
			opacity: 0.4;
		}
	}

	&:hover .control {
		transform: translateX(0px);
	}
}
